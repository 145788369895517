#qrcode {
  width: 100% !important;
  height: auto !important;
}
@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-up {
  animation: slide-up 0.5s ease;
}

#bg-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
#container {
  /* position: fixed;
  top: 0;
  left: 0; */
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
#bg-wrap svg {
  width: 100%;
  height: 100%;
}
input {
  background-color: transparent;
}
